<template>
  <el-dialog :title="title" @closed="_closed" :visible.sync="visible" width="50%">
    <l-table :option="tableOption" :data="dataList"> </l-table>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      enterpriseCode: null,
      dataList: null,
      tableOption: {
        stripe: true,
        height: document.documentElement.clientHeight - 280 - 50 + 'px',
        columns: [
          { label: '序号', type: 'index' },
          { label: '分类名称', prop: 'categoryName' }
        ],
        operation: {
          width: '200',
          header: { type: 'warning', icon: 'el-icon-plus', label: '添加', click: this.add },
          buttons: [
            { type: 'primary', icon: 'el-icon-edit', label: '修改', click: this.edit },
            { type: 'danger', icon: 'el-icon-delete', label: '删除', click: this.del }
          ]
        }
      }
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    async getDataList() {
      this.dataList = await this.$lgh.get('api/enterprise/asset/category', { enterpriseCode: this.enterpriseCode })
    },
    add() {
      this.$lgh.msgPrompt('输入分类名称', p => {
        this.$lgh
          .post('api/enterprise/asset/category/addoredit', {
            enterpriseCode: this.enterpriseCode,
            categoryName: p
          })
          .then(r => {
            this.getDataList()
          })
      })
    },
    edit(item) {
      this.$lgh.msgPrompt(
        '输入分类名称',
        p => {
          var data = Object.assign({}, item, {
            categoryName: p
          })
          this.$lgh.post('api/enterprise/asset/category/addoredit', data).then(r => {
            this.getDataList()
          })
        },
        { inputValue: item.categoryName }
      )
    },
    del(item) {
      this.$lgh.msgConfirm('确认删除?', p => {
        this.$lgh
          .get('api/enterprise/asset/category/delete', {
            enterpriseCode: item.enterpriseCode,
            categoryCode: item.categoryCode
          })
          .then(r => {
            this.getDataList()
          })
      })
    }
  }
}
</script>

<style></style>
